<template>
  <div :class="'menu ' + this.currentClass">
    <ul>
      <li :class="this.currentClass == 'front' ? 'active' : ''">
        <a href="#" v-on:click.prevent="switchPage('home')">Home</a>
      </li>
      <li :class="this.currentClass == 'top' ? 'active' : ''">
        <a href="#" v-on:click.prevent="switchPage('about')">About</a>
      </li>
      <li :class="this.currentClass == 'back' ? 'active' : ''">
        <a href="#" v-on:click.prevent="switchPage('skills')">Skills</a>
      </li>
      <li :class="this.currentClass == 'bottom' ? 'active' : ''">
        <a href="#" v-on:click.prevent="switchPage('contact')">Contact</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComp",
  props: { current: { type: String, default: "front" } },
  data() {
    return { currentClass: this.current };
  },
  watch: {
    current() {
      this.currentClass = this.current;
    },
  },
  methods: {
    switchPage(p) {
      switch (p) {
        case "skills":
          this.currentClass = "back";
          break;
        case "about":
          this.currentClass = "top";
          break;
        case "contact":
          this.currentClass = "bottom";
          break;
        default:
          this.currentClass = "front";
          break;
      }
      this.$emit("change", this.currentClass);
    },
  },
};
</script>

<style></style>
