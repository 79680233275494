<template>
  <div class="main-container">
    <transition name="fade">
      <Menu
        :current="currentClass"
        :class="{ shadow: currentClass == 'top' }"
        v-if="showMenu"
        v-on:change="switchPage"
      />
    </transition>
    <div :class="'cont3d ' + currentClass">
      <div class="prixi3dbox">
        <div class="prixi3dface prixi3dface--front">
          <section class="intro">
            <div class="content">
              <div class="circle-container-main">
                <div
                  class="circle-container"
                  v-for="ind in particles"
                  :key="ind"
                >
                  <div class="circle"></div>
                </div>
              </div>
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-5 text-center text-lg-start">
                    <div
                      @mouseleave="hoverme($event)"
                      :class="'me ' + animateClassMe"
                    >
                      <img src="@/assets/prixi-nobg.png" />
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div class="gtp">
                      <h1 class="greetings">Hello!</h1>
                      <div class="typebox">
                        <span>I'm</span>&nbsp;<span id="typewriter"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="waveBox">
                <svg
                  class="waves"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 30 150 28"
                  preserveAspectRatio="none"
                  shape-rendering="auto"
                >
                  <defs>
                    <path
                      id="wave"
                      d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                  </defs>
                  <g class="parallax">
                    <use xlink:href="#wave" x="48" y="7" fill="#fff" />
                  </g>
                </svg>
              </div>
            </div>
          </section>
        </div>
        <div class="prixi3dface prixi3dface--top">
          <section class="bio">
            <h2 class="title mb-4">About Me</h2>
            <img class="me-anim" src="@/assets/me-anim.png" />
            <perfect-scrollbar :options="scrollOptions">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <p class="text-start">
                      I'm a senior full-stack developer who's a big fan of the
                      Rock music. Some of my favorite bands are Pink Floyd,
                      Black Sabbath, The Beatles, Led Zeppelin, The Rolling
                      Stones, Queen, Scorpions, AC/DC, Dio, Ozzy, Aerosmith,
                      Guns N' Roses, Van Halen, Metallica and many more :) I
                      have 15+ years of extensive experience in structuring,
                      developing and implementing interactive SaaS Applications,
                      innovative use of technology to drive traffic and engage
                      users. Skilled in front and back end design and
                      development. Experienced with all the stages of the web
                      and software development lifecycle, from concept and
                      development through quality assurance and tuning.
                    </p>
                    <p v-if="moreBioData" class="text-start">
                      At the age of 15, I started to learn graphic design and I
                      was really interested in tools such as Adobe Photoshop.
                      After taking a few courses after school, I was able to
                      start designing graphical material for our school as a
                      contractor (Yes, my first job). After a couple of years,
                      I've decided that now is the time for me to move to the
                      next level which was programming. I was always curious
                      about the fact that how computer software works, so I
                      spent some time to learn how to code! At the time, I
                      started by learning C and after that, I learned how to
                      code in Visual Basic (VB6) as it was very popular at the
                      time. Next, taking that WWW was starting to become popular
                      and everyone wanted to have their website, I started to
                      learn PHP, HTML, JAVASCRIPT and CSS by borrowing a book
                      from our school's library. after A few months, I was able
                      to code in PHP and finally got my very first website
                      project which was an online magazine (A very popular one).
                      So, I was able to Design and Code for my projects. While I
                      was working on one of my projects (Online Food Ordering)
                      in late 2010, I got to work with a network security
                      engineer and that raised many questions for me which led
                      me to learn about network security and how to patch these
                      vulnerabilities. I've done my fair share of "Snooping
                      Around" while learning about these matters and that helped
                      me a lot to protect the projects that I've created even
                      more. When I got to university to study software
                      engineering, I teamed up with one of my classmates and
                      started my very first company called Neotis Studio, We
                      worked with small and large clients alike for about 10
                      years until I moved to the United States in December 2017.
                    </p>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </section>
        </div>
        <div class="prixi3dface prixi3dface--bottom">
          <section class="contact">
            <h2 class="title">Get in Touch</h2>
            <div class="container">
              <div class="row icons">
                <div class="col-6 col-md-6 col-lg-3 text-center">
                  <a
                    class="icon"
                    target="_blank"
                    href="https://www.linkedin.com/in/prastegar/"
                  >
                    <div>
                      <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </div>
                  </a>
                </div>
                <div class="col-6 col-md-6 col-lg-3 text-center">
                  <a
                    class="icon"
                    target="_blank"
                    href="https://github.com/iprixi"
                  >
                    <i class="fa fa-github" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-6 col-md-6 col-lg-3 text-center">
                  <a
                    class="icon"
                    target="_blank"
                    href="https://twitter.com/imprixi"
                  >
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-6 col-md-6 col-lg-3 text-center">
                  <a
                    class="icon"
                    target="_blank"
                    href="https://www.instagram.com/imprixi/"
                  >
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-12 text-center pt-lg-5">
                  <span class="mailer"
                    >or feel free to send me an email at
                    <a href="mailto:pouya@prixi.com">pouya@prixi.com</a></span
                  >
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="prixi3dface prixi3dface--left"></div>
        <div class="prixi3dface prixi3dface--right"></div>
        <div class="prixi3dface prixi3dface--back">
          <section class="skills">
            <h2 class="title">My Skill Set</h2>
            <div class="container">
              <div class="row">
                <div class="col text-center">
                  <Carousel>
                    <Slide v-for="(item, ind) in skillcats" :key="ind">
                      <div class="carousel__item">
                        <div class="cat-title">{{ item.name }}</div>
                        <perfect-scrollbar
                          style="height:60vh;"
                          :options="scrollOptions"
                        >
                          <ul class="skill-list">
                            <li v-for="(s, si) in item.items" :key="si">
                              <div class="item-title">{{ s.title }}</div>
                              <div class="progress">
                                <div
                                  class="progress-bar progress-bar-striped"
                                  role="progressbar"
                                  :style="'width:' + s.value + '%'"
                                  :aria-valuenow="s.value"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </li>
                          </ul>
                        </perfect-scrollbar>
                      </div>
                    </Slide>
                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typewriter from "typewriter-effect/dist/core";
import Menu from "@/components/menu";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "HelloWorld",
  components: { Menu, Carousel, Slide, Navigation },
  data() {
    return {
      particles: 100,
      moreBioData: true,
      selectedIndex: 0,
      animateClassIntro: "",
      animateClassMe: "",
      currentClass: "front",
      skillItems: [],
      skillcats: [
        {
          name: "Backend",
          items: [
            { title: "PHP", value: 100 },
            { title: "Python", value: 95 },
            { title: "NodeJS", value: 100 },
            { title: "SQL", value: 100 },
            { title: "NoSQL", value: 90 },
          ],
        },
        {
          name: "Frontend",
          items: [
            { title: "Vue", value: 100 },
            { title: "React", value: 95 },
            { title: "Angular", value: 90 },
            { title: "Flutter", value: 100 },
            { title: "Javascript", value: 100 },
            { title: "Sass", value: 100 },
            { title: "CSS3", value: 100 },
            { title: "HTML5", value: 100 },
          ],
        },
        {
          name: "DevOps",
          items: [
            { title: "Git", value: 100 },
            { title: "SVN", value: 100 },
            { title: "Webpack", value: 95 },
            { title: "Gradle", value: 100 },
            { title: "Jenkins", value: 100 },
            { title: "Selenium", value: 100 },
            { title: "Firebase", value: 90 },
            { title: "AWS", value: 100 },
          ],
        },
        {
          name: "Other",
          items: [
            { title: "Photoshop", value: 95 },
            { title: "Figma", value: 85 },
            { title: "Zeplin", value: 80 },
            { title: "Tensorflow", value: 89 },
            { title: "Dialogflow", value: 100 },
          ],
        },
      ],
      scrollOptions: {
        suppressScrollX: true,
      },
      showMenu: true,
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    this.animateClassMe = "animate";
    this.animateClassIntro = "animate";
    setTimeout(this.startTyping, 1800);
  },
  methods: {
    startTyping() {
      new Typewriter("#typewriter", {
        strings: [
          "Pouya! (A.K.A. PRiXi).",
          "a Software Engineer.",
          "a Back-end Developer.",
          "a Front-end Developer.",
          "a Flutter Developer.",
          "a DevOps Engineer.",
        ],
        autoStart: true,
        deleteSpeed: 10,
        delay: 80,
        pauseFor: 1000,
        loop: true,
      });
    },
    showMoreInfo() {
      this.moreBioData = !this.moreBioData;
    },
    switchIndex(ind) {
      this.selectedIndex = ind;
    },
    hoverme(e) {
      e;
      this.animateClassMe = "";
    },
    switchPage(c) {
      if (c == this.currentClass) return false;
      this.showMenu = false;
      this.currentClass = c;
      setTimeout(() => {
        this.showMenu = true;
      }, 600);
    },
  },
};
</script>
